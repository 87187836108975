import type { FunctionComponent } from 'react';
import { Suspense } from 'react';

import type { RouteId } from '@trello/router';
import { useRouteParams } from '@trello/router';
import { useLazyComponent } from '@trello/use-lazy-component';

export const LazyAppManagement: FunctionComponent = () => {
  const routeParams = useRouteParams<
    typeof RouteId.POWER_UP_ADMIN | typeof RouteId.POWER_UP_EDIT
  >();
  const section =
    'section' in routeParams ? routeParams.section : routeParams.idPlugin;
  const subsection =
    'subsection' in routeParams ? routeParams.subsection : null;
  const AppManagement = useLazyComponent(
    () => import(/* webpackChunkName: "app-management" */ './AppManagement'),
    {
      namedImport: 'AppManagement',
    },
  );
  return (
    <Suspense fallback={null}>
      <AppManagement section={section} subsection={subsection} />
    </Suspense>
  );
};
